import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { ConstProvider } from "../contexts/AuthContext";
import RequireAuth from "./global/requireAuth/RequireAuth";
import RequireAdminAuth from "./global/requireAuth/RequireAdminAuth";
import "../css/imports.css";
import ReactGA from "react-ga";
import { CookiesProvider } from "react-cookie";
import AuthProvider from "../components/global/authProvider/AuthProvider";
import spinner from "../img/loader/Spinner-1s-200px.svg";
import { SUPPORTED_LOCALES } from "../constants";
import { ROUTES } from "../routes";
import SmartImage from "./global/image/smartImage/SmartImage";
import SEOHelmetMeta from "./global/seoHelmetMeta/SEOHelmetMeta";

ReactGA.initialize("UA-124627850-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const Home = React.lazy(() => import("./pages/home/Home"));

// Admin Only
const AdminPanel = React.lazy(() => import("./pages/admin/AdminPanel"));

const TrainerReviewAdmin = React.lazy(
  () => import("./pages/admin/trainerReview/TrainerReview")
);

const FeedbackBoard = React.lazy(
  () => import("./pages/admin/feedbackBoard/FeedbackBoard")
);

const UsersBoard = React.lazy(
  () => import("./pages/admin/usersBoard/UsersBoard")
);

// COURSE
const CourseMainVideo = React.lazy(
  () => import("../components/pages/user_sub/videoMeeting/COURSEMainVideo")
);
const CallerVideo = React.lazy(
  () => import("../components/pages/user_sub/videoMeeting/COURSECallerVideo")
);
const AnswerVideo = React.lazy(
  () => import("../components/pages/user_sub/videoMeeting/COURSEAnswerVideo")
);
// Normal
const ScrollToTopGlobalButton = React.lazy(
  () =>
    import("./global/buttons/ScrollToTopGlobalButton/ScrollToTopGlobalButton")
);

/* const CompanyFilter = React.lazy(
  () => import("./pages/company_sub/companyFilter/CompanyFilter")
); */

const TrainerSearchFullPage = React.lazy(
  () =>
    import("./pages/company_sub/trainerSearchFullPage/TrainerSearchFullPage")
);

const CompanyProfile = React.lazy(
  () => import("./pages/company_sub/companyProfile/CompanyProfile")
);

const CompanyAddNew = React.lazy(
  () => import("./pages/company_sub/companyAddNew/CompanyAddNew")
);

const RequireCredentialVerification = React.lazy(
  () => import("./global/requireAuth/RequireCredentialVerification")
);

const RequireTestingAuth = React.lazy(
  () => import("./global/requireAuth/RequireTestingAuth")
);

const MainVideoPage = React.lazy(
  () => import("./pages/user_sub/videoMeeting/MainVideoPage")
);

/* const CredentialVerification = React.lazy(
  () =>
    import(
      "./pages/company_sub/companyAddNew/CredentialVerification/CredentialVerification"
    )
); */

const ZoomAuthentication = React.lazy(
  () => import("./pages/zoom/zoomAuthentication/ZoomAuthentication")
);

const CreateClientForm = React.lazy(
  () =>
    import("./pages/company_sub/clientForms/createClientForm/CreateClientForm")
);

const ClientForms = React.lazy(
  () => import("./pages/company_sub/clientForms/ClientForms")
);

const SearchPopUp = React.lazy(() => import("./global/search/SearchPopUp"));
const Header = React.lazy(() => import("./global/header/Header"));
const SideNavMobile = React.lazy(
  () => import("./global/header/components/SideNavMobile")
);

/* const UploadProfileImage = React.lazy(
  () =>
    import(
      "./pages/user_sub/profile/profileheader/profileImage/UploadProfileImage"
    )
); */
/* const UploadBannerImage = React.lazy(
  () =>
    import(
      "./pages/user_sub/profile/profileheader/uploadbannerImage/UploadBannerImage"
    )
); */

const Login = React.lazy(() => import("./pages/authorisation_sub/login/Login"));
const SessionExpired = React.lazy(
  () => import("./pages/authorisation_sub/sessionexpired/SessionExpired")
);

const Register = React.lazy(
  () => import("./pages/authorisation_sub/register/Register")
);
const ResetPassword = React.lazy(
  () => import("./pages/authorisation_sub/resetPassword/ResetPassword")
);
const ForgottenPassword = React.lazy(
  () => import("./pages/authorisation_sub/forgottenPassword/ForgottenPassword")
);
const EmailVerification = React.lazy(
  () => import("./pages/authorisation_sub/emailverification/EmailVerification")
);

/* const Overview = React.lazy(
  () => import("./pages/controlPanel_sub/pageSectionTabs/overview/Overview")
); */

const ControlPanel = React.lazy(
  () => import("./pages/controlPanel_sub/ControlPanel")
);

/* const MyEvents = React.lazy(
  () => import("./pages/exerciseClasses_sub/myEvents/MyEvents")
); */

const ExerciseClassMetrics = React.lazy(
  () =>
    import(
      "./pages/exerciseClasses_sub/exerciseClassMetrics/ExerciseClassMetrics"
    )
);

const ClientMeta = React.lazy(
  () => import("./pages/company_sub/clientMeta/ClientMeta")
);

/* const MyProducts = React.lazy(
  () => import("./pages/shop_sub/myProducts/MyProducts")
); */

const CreatedStripeAccount = React.lazy(
  () => import("./pages/payments/createdStripeAccount/CreatedStripeAccount")
);

const MyProgress = React.lazy(
  () => import("./pages/company_sub/myProgress/MyProgress")
);
const Profile = React.lazy(() => import("./pages/user_sub/profile/Profile"));

/* const ProfileSentPendingContacts = React.lazy(
  () =>
    import(
      "./pages/controlPanel_sub/pageSectionTabs/profileSentPendingContacts/ProfileSentPendingContacts"
    )
); */
const Profiles = React.lazy(
  () => import("./pages/user_sub/profileSearchPage/ProfileSearchPage")
);
const Calendar = React.lazy(
  () => import("./pages/calendar/userCalendar/Calendar")
);

const ExercisePrograms = React.lazy(
  () => import("./pages/createProgram/ExerciseProgramList")
);

const ProgramTemplate = React.lazy(
  () => import("./pages/createProgram/view/ProgramTemplate2")
);

const ProgramTemplateCreateNew = React.lazy(
  () => import("./pages/createProgram/create/ProgramTemplateCreateNew")
);

const EditExerciseProgram = React.lazy(
  () => import("./pages/createProgram/edit/EditExerciseProgram")
);

const UserPrograms = React.lazy(
  () => import("./pages/myPrograms/UserPrograms")
);

const CalendarOptions = React.lazy(
  () => import("./pages/calendar/userCalendar/CalendarOptions")
);

const RecurringCalendarBreaks = React.lazy(
  () => import("./pages/calendar/userCalendar/RecurringCalendarBreaks")
);

const EditRecurringDaysOff = React.lazy(
  () => import("./pages/calendar/userCalendar/EditRecurringDaysOff")
);

/* const CompanyCalendar = React.lazy(
  () => import("./pages/calendar/companyCalendar/CompanyCalendar")
); */
const Events = React.lazy(
  () => import("./pages/exerciseClasses_sub/events/Events")
);
const Event = React.lazy(
  () => import("./pages/exerciseClasses_sub/event/Event")
);
/* const ImageSliderFullScreen = React.lazy(
  () => import("./global/galleries/ImageSliderFullScreen")
); */
const Messages = React.lazy(() => import("./pages/user_sub/messages/Messages"));

const ExerciseDatabase = React.lazy(
  () => import("./pages/exercises_sub/exerciseDatabase/ExerciseDatabase")
);
const Exercise = React.lazy(
  () => import("./pages/exercises_sub/exercise/Exercise")
);
const AnteriorMuscles = React.lazy(
  () => import("./pages/exercises_sub/exercise/AnteriorMuscles")
);
const AnteriorMuscleSingle = React.lazy(
  () => import("./pages/exercises_sub/exercise/AnteriorMusclesSingle")
);
const PosteriorMuscles = React.lazy(
  () => import("./pages/exercises_sub/exercise/PosteriorMuscles")
);
const PosteriorMuscleSingle = React.lazy(
  () => import("./pages/exercises_sub/exercise/PosteriorMusclesSingle")
);
const HumanDiagram = React.lazy(
  () => import("./pages/exercises_sub/humanDiagram/HumanDiagram")
);
const BodyFatPercentageCalculator = React.lazy(
  () =>
    import(
      "./pages/tools_sub/bodyFatPercentageCalculator/BodyFatPercentageCalculator"
    )
);
const IntervalTimer = React.lazy(
  () => import("./pages/tools_sub/intervalTimer/IntervalTimer")
);
const ShopOverview = React.lazy(
  () => import("./pages/shop_sub/shopOverview/ShopOverview")
);
const AddNewProduct = React.lazy(
  () => import("./pages/shop_sub/addNewProduct/AddNewProduct")
);

const EditExistingProduct = React.lazy(
  () => import("./pages/shop_sub/editExistingProduct/EditExistingProduct")
);

const Cart = React.lazy(() => import("./pages/shop_sub/cart/Cart"));
// Comment out below
const Checkout = React.lazy(() => import("./pages/shop_sub/checkout/Checkout"));

const StripeStarter = React.lazy(
  () => import("./pages/shop_sub/stripe/StripeStarter")
);

const SuccessfulPayment = React.lazy(
  () => import("./pages/shop_sub/successfulPayment/SuccessfulPayment")
);

const IndividualPurchases = React.lazy(
  () =>
    import("./pages/payments/purchases/individualPurchase/IndividualPurchase")
);

const PurchaseHistory = React.lazy(
  () => import("./pages/shop_sub/purchaseHistory/PurchaseHistory")
);

const PurchaseHistoryProductDetails = React.lazy(
  () => import("./pages/shop_sub/purchaseHistory/PurchaseDetails")
);
const CategoryOverview = React.lazy(
  () => import("./pages/shop_sub/categoryOverview/CategoryOverview")
);
const ProductOverview = React.lazy(
  () => import("./pages/shop_sub/productOverview/ProductOverview")
);
const Articles = React.lazy(
  () => import("./pages/articles_sub/articles/Articles")
);
const AddNewArticle = React.lazy(
  () => import("./pages/articles_sub/addNewArticle/AddNewArticle")
);

const Article = React.lazy(
  () => import("./pages/articles_sub/article/Article")
);
const Feedback = React.lazy(() => import("./pages/user_sub/feedback/Feedback"));

const NewBugReportButton = React.lazy(
  () => import("./global/buttons/newBugReportButton/NewBugReportButton")
);

const Contact = React.lazy(() => import("./pages/contact/Contact"));

const CookiePolicy = React.lazy(() => import("./pages/legal_sub/CookiePolicy"));
const PrivacyPolicy = React.lazy(
  () => import("./pages/legal_sub/PrivacyPolicy")
);
const TermsAndConditions = React.lazy(
  () => import("./pages/legal_sub/TermsAndConditions")
);
const UnauthorisedPermissions = React.lazy(
  () => import("./pages/authorisation_sub/unauthorised/UnauthorisedPermissions")
);
const CookieNotification = React.lazy(
  () => import("./pages/legal_sub/CookieNotification")
);
const UpdateConfirmationPopUp = React.lazy(
  () =>
    import("./global/confirmations/updateConfirmation/UpdateConfirmationPopUp")
);
const FeedbackNewTicket = React.lazy(
  () => import("./pages/user_sub/feedback/FeedbackNewTicket")
);
/* 
const DeleteConfirmationPopUp = React.lazy(
  () =>
    import("./global/confirmations/deleteConfirmation/DeleteConfirmationPopUp")
); */
/* 
const MyFiles = React.lazy(() => import("./pages/user_sub/myfiles/MyFiles")); */

const ProductDetailsID = React.lazy(
  () => import("./pages/shop_sub/productDetailsID/ProductDetailsID")
);

const ErrorConfirmationPopUp = React.lazy(
  () =>
    import("./global/confirmations/errorConfirmation/errorConfirmationPopUp")
);

const Footer = React.lazy(() => import("./global/footer/Footer"));
const Error404 = React.lazy(() => import("./pages/errors/Error404"));

const App = () => {
  console.log(1);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const LocaleRedirect = () => {
    console.log(3);
    const navigate = useNavigate();
    const { locale } = useParams();

    useEffect(() => {
      const savedLocale = localStorage.getItem("preferredLocale");

      const browserLocale = navigator.language.toLowerCase();
      const supportedLocales = SUPPORTED_LOCALES;
      const defaultLocale = "en-gb";

      const newLocale =
        savedLocale ||
        (supportedLocales.includes(browserLocale)
          ? browserLocale
          : defaultLocale);

      // Prevent infinite locale appending
      const currentPath = window.location.pathname;
      const pathSegments = currentPath.split("/").filter(Boolean);

      // If the first segment is already a locale, do nothing
      if (supportedLocales.includes(pathSegments[0])) {
        return;
      }

      // Preserve query params
      const queryString = window.location.search;

      // Otherwise, prepend the correct locale and navigate
      const newPath = `/${newLocale}${currentPath}${queryString}`;
      navigate(newPath, { replace: true });
    }, [locale, navigate]);

    return null;
  };

  // Define your types ------------------------------------------------
  interface CallStatus {
    haveMedia: boolean;
    videoEnabled?: boolean;
    audioEnabled?: boolean;
    isConnected?: boolean;
    hasRemoteStream?: boolean;
    errorMessage?: string;
    callStartTime?: Date;
    answer?: RTCSessionDescriptionInit;
    myRole?: string;
  }

  interface OfferData {
    offer: RTCSessionDescriptionInit;
    answer?: RTCSessionDescriptionInit;
    answerUserName?: string;
  }

  // You might need to replace these with the actual types of your streams and peer connection

  const [callStatus, updateCallStatus] = useState<CallStatus>({
    haveMedia: false,
  });
  const [localStream, setLocalStream] = useState<MediaStream | null>(null);
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
  const [peerConnection, setPeerConnection] =
    useState<RTCPeerConnection | null>(null);
  const [userName, setUserName] = useState<string>("");
  const [offerData, setOfferData] = useState<OfferData>();

  return (
    <>
      <CookiesProvider>
        <ConstProvider>
          <Suspense
            fallback={
              <div className="loaderSpinner">
                <SmartImage
                  src={spinner}
                  fallbackSrc={spinner}
                  alt="Loading Spinner"
                  loading="eager"
                />
              </div>
            }
          >
            <SEOHelmetMeta
              title="Urbacise"
              description="A fitness resource with workouts, trainers, products & more."
            />

            <Header />
            <SideNavMobile />
            <div className="container">
              <AuthProvider>
                <Routes>
                  {/* Public paths */}
                  <Route path="/" element={<LocaleRedirect />} />
                  <Route path="/:locale" element={<Home />} />
                  <Route
                    path="/video"
                    element={
                      <CourseMainVideo
                        callStatus={callStatus}
                        updateCallStatus={updateCallStatus}
                        localStream={localStream}
                        setLocalStream={setLocalStream}
                        remoteStream={remoteStream}
                        setRemoteStream={setRemoteStream}
                        peerConnection={peerConnection}
                        setPeerConnection={setPeerConnection}
                        userName={userName}
                        setUserName={setUserName}
                        offerData={offerData}
                        setOfferData={setOfferData}
                      />
                    }
                  />
                  <Route
                    path="/offer"
                    element={
                      <CallerVideo
                        callStatus={callStatus}
                        updateCallStatus={updateCallStatus}
                        localStream={localStream}
                        // setLocalStream={setLocalStream}
                        remoteStream={remoteStream}
                        // setRemoteStream={setRemoteStream}
                        peerConnection={peerConnection}
                        userName={userName}
                        //  setUserName={setUserName}
                      />
                    }
                  />
                  <Route
                    path="/answer"
                    element={
                      <AnswerVideo
                        callStatus={callStatus}
                        updateCallStatus={updateCallStatus}
                        localStream={localStream}
                        // setLocalStream={setLocalStream}
                        remoteStream={remoteStream}
                        // setRemoteStream={setRemoteStream}
                        peerConnection={peerConnection}
                        userName={userName}
                        // setUserName={setUserName}
                        offerData={offerData}
                      />
                    }
                  />
                  {/*  <Route path="/trainers-search" element={<CompanyFilter />} /> */}
                  <Route
                    path="/company/profile/:company_slug"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/company/profile/:company_slug"
                    element={
                      <RequireTestingAuth>
                        <CompanyProfile />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path="/client-sign-up-form/:client_form_id"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/client-sign-up-form/:client_form_id"
                    element={<ClientForms />}
                  />
                  <Route
                    path={ROUTES.EVENTS.ROOT}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.EVENTS.ROOT}`}
                    element={
                      <RequireTestingAuth>
                        <Events />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path="/events/:global_product_id"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/events/:global_product_id"
                    element={
                      <RequireTestingAuth>
                        <Event />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path={ROUTES.EXERCISES.ROOT}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.EXERCISES.ROOT}`}
                    element={<ExerciseDatabase />}
                  />
                  <Route
                    path="/exercises/:exercise_slug"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/exercises/:exercise_slug"
                    element={<Exercise />}
                  />
                  <Route
                    path={ROUTES.ANTERIOR_MUSCLES.ROOT}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.ANTERIOR_MUSCLES.ROOT}`}
                    element={<AnteriorMuscles />}
                  />
                  <Route
                    path="/anterior-muscles/:anterior_slug"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/anterior-muscles/:anterior_slug"
                    element={<AnteriorMuscleSingle />}
                  />
                  <Route
                    path={ROUTES.POSTERIOR_MUSCLES.ROOT}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.POSTERIOR_MUSCLES.ROOT}`}
                    element={<PosteriorMuscles />}
                  />
                  <Route
                    path="/posterior-muscles/:posterior_slug"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/posterior-muscles/:posterior_slug"
                    element={<PosteriorMuscleSingle />}
                  />
                  <Route path="/human-diagram" element={<LocaleRedirect />} />
                  <Route
                    path="/:locale/human-diagram"
                    element={<HumanDiagram />}
                  />
                  <Route
                    path={ROUTES.BODY_FAT_CALCULATOR}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.BODY_FAT_CALCULATOR}`}
                    element={<BodyFatPercentageCalculator />}
                  />
                  <Route path="/interval-timer" element={<LocaleRedirect />} />
                  <Route
                    path="/:locale/interval-timer"
                    element={<IntervalTimer />}
                  />
                  <Route path={ROUTES.SHOP.ROOT} element={<LocaleRedirect />} />
                  <Route
                    path={`/:locale${ROUTES.SHOP.ROOT}`}
                    element={<ShopOverview />}
                  />
                  <Route path={ROUTES.CART} element={<LocaleRedirect />} />
                  <Route
                    path={`/:locale${ROUTES.CART}`}
                    element={
                      <RequireTestingAuth>
                        <Cart />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path="/shop/:category_slug"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/shop/:category_slug"
                    element={<CategoryOverview />}
                  />
                  <Route
                    path="/shop/:category_slug/:slug"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/shop/:category_slug/:slug"
                    element={<ProductOverview />}
                  />
                  <Route
                    path={ROUTES.ARTICLES.ROOT}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.ARTICLES.ROOT}`}
                    element={<Articles />}
                  />
                  <Route
                    path="/articles/:article_slug"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/articles/:article_slug"
                    element={<Article />}
                  />
                  <Route path={ROUTES.LOGIN} element={<LocaleRedirect />} />
                  <Route
                    path={`/:locale${ROUTES.LOGIN}`}
                    element={
                      <RequireTestingAuth>
                        <Login />
                      </RequireTestingAuth>
                    }
                  />
                  <Route path="/session-expired" element={<LocaleRedirect />} />
                  <Route
                    path="/:locale/session-expired"
                    element={
                      <RequireTestingAuth>
                        <SessionExpired />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path="/resetpassword/:reset_token"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/resetpassword/:reset_token"
                    element={
                      <RequireTestingAuth>
                        <ResetPassword />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path={ROUTES.FORGOT_PASSWORD}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.FORGOT_PASSWORD}`}
                    element={
                      <RequireTestingAuth>
                        <ForgottenPassword />
                      </RequireTestingAuth>
                    }
                  />
                  <Route path={ROUTES.REGISTER} element={<LocaleRedirect />} />
                  <Route
                    path={`/:locale${ROUTES.REGISTER}`}
                    element={
                      <RequireTestingAuth>
                        <Register />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path="/emailverification/:emailvalidation_token"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/emailverification/:emailvalidation_token"
                    element={
                      <RequireTestingAuth>
                        <EmailVerification />
                      </RequireTestingAuth>
                    }
                  />
                  <Route path={ROUTES.CONTACT} element={<LocaleRedirect />} />
                  <Route
                    path={`/:locale${ROUTES.CONTACT}`}
                    element={<Contact />}
                  />
                  <Route
                    path={ROUTES.COOKIE_POLICY}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.COOKIE_POLICY}`}
                    element={<CookiePolicy />}
                  />
                  <Route path="/privacy-policy" element={<LocaleRedirect />} />
                  <Route
                    path="/:locale/privacy-policy"
                    element={<PrivacyPolicy />}
                  />
                  <Route path={ROUTES.TERMS} element={<LocaleRedirect />} />
                  <Route
                    path={`/:locale${ROUTES.TERMS}`}
                    element={<TermsAndConditions />}
                  />
                  <Route path="/unauthorised" element={<LocaleRedirect />} />
                  <Route
                    path="/:locale/unauthorised"
                    element={<UnauthorisedPermissions />}
                  />
                  <Route path="/trainers-search" element={<LocaleRedirect />} />
                  <Route
                    path="/:locale/trainers-search"
                    element={
                      <RequireTestingAuth>
                        <TrainerSearchFullPage />
                      </RequireTestingAuth>
                    }
                  />
                  {/* Private paths */}
                  <Route path="/checkout" element={<LocaleRedirect />} />
                  <Route
                    path="/:locale/checkout"
                    element={
                      <RequireAuth>
                        <StripeStarter />
                      </RequireAuth>
                    }
                  />
                  // Comment out below
                  {/*  <Route path="/checkout" element={<LocaleRedirect />} />
                  <Route
                    path="/checkout"
                    element={
                      <RequireAuth>
                        <Checkout />
                      </RequireAuth>
                    }
                  /> */}
                  <Route
                    path={ROUTES.ACCOUNT_CREATION_CONFIRMATION}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.ACCOUNT_CREATION_CONFIRMATION}`}
                    element={
                      <RequireAuth>
                        <CreatedStripeAccount />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.SUCCESSFUL_PAYMENT}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.SUCCESSFUL_PAYMENT}`}
                    element={
                      <RequireAuth>
                        <SuccessfulPayment />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/individual_purchase/:individual_purchases_id"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/individual_purchase/:individual_purchases_id"
                    element={
                      <RequireAuth>
                        <IndividualPurchases />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.PURCHASE_HISTORY.ROOT}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.PURCHASE_HISTORY.ROOT}`}
                    element={
                      <RequireAuth>
                        <PurchaseHistory />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/purchase-history/product-details/:group_purchase_id"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/purchase-history/product-details/:group_purchase_id"
                    element={
                      <RequireAuth>
                        <PurchaseHistoryProductDetails />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.CONTROL_PANEL.ROOT}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.CONTROL_PANEL.ROOT}`}
                    element={
                      <RequireAuth>
                        <ControlPanel />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.ADD_NEW_COMPANY}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.ADD_NEW_COMPANY}`}
                    element={
                      <RequireAuth>
                        <RequireCredentialVerification>
                          <CompanyAddNew />
                        </RequireCredentialVerification>
                      </RequireAuth>
                    }
                  />
                  {/*  <Route
                path="/credential-verification"
                element={
                  <RequireAuth>
                    <CredentialVerification />
                  </RequireAuth>
                }
                /> */}
                  <Route
                    path="/create-client-form"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/create-client-form"
                    element={
                      <RequireAuth>
                        <CreateClientForm />
                      </RequireAuth>
                    }
                  />
                  {/*  <Route
                path="/wysiwyg"
                element={
                  <RequireAuth>
                    <WYSIWYG />
                  </RequireAuth>
                }
                /> */}
                  <Route
                    path="/zoom-authentication"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/zoom-authentication"
                    element={
                      <RequireAuth>
                        <ZoomAuthentication />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.ADD_NEW_ARTICLE}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.ADD_NEW_ARTICLE}`}
                    element={
                      <RequireAuth>
                        <AddNewArticle />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/events/:global_product_id/metrics"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/events/:global_product_id/metrics"
                    element={
                      <RequireAuth>
                        <ExerciseClassMetrics />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/clientmeta/:user_slug"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/clientmeta/:user_slug"
                    element={
                      <RequireAuth>
                        <ClientMeta />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/product-details/:product_id"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/product-details/:product_id"
                    element={
                      <RequireAuth>
                        <ProductDetailsID />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/progress/:user_slug"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/progress/:user_slug"
                    element={
                      <RequireAuth>
                        <MyProgress />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.ADD_NEW_PRODUCT}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.ADD_NEW_PRODUCT}`}
                    element={
                      <RequireAuth>
                        <AddNewProduct />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/edit-product/:product_slug"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/edit-product/:product_slug"
                    element={
                      <RequireAuth>
                        <EditExistingProduct />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.PROFILES.ROOT}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.PROFILES.ROOT}`}
                    element={
                      <RequireAuth>
                        <Profiles />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/calendar/:profile_slug"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/calendar/:profile_slug"
                    element={
                      <RequireAuth>
                        <Calendar />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/exercise-programs/:profile_slug"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/exercise-programs/:profile_slug"
                    element={
                      <RequireAuth>
                        <ExercisePrograms />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/exercise-program-view/:program_id"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/exercise-program-view/:program_id"
                    element={
                      <RequireAuth>
                        <ProgramTemplate />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.EXERCISE_PROGRAM_CREATE}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.EXERCISE_PROGRAM_CREATE}`}
                    element={
                      <RequireAuth>
                        <ProgramTemplateCreateNew />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/exercise-program-edit/:program_id"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/exercise-program-edit/:program_id"
                    element={
                      <RequireAuth>
                        <EditExerciseProgram />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.USER_PROGRAMS}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.USER_PROGRAMS}`}
                    element={
                      <RequireAuth>
                        <UserPrograms />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.CALENDAR.OPTIONS}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.CALENDAR.OPTIONS}`}
                    element={
                      <RequireAuth>
                        <CalendarOptions />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.CALENDAR.EDIT_RECURRING_DAYS_OFF}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.CALENDAR.EDIT_RECURRING_DAYS_OFF}`}
                    element={
                      <RequireAuth>
                        <EditRecurringDaysOff />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.CALENDAR.EDIT_RECURRING_BREAKS}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.CALENDAR.EDIT_RECURRING_BREAKS}`}
                    element={
                      <RequireAuth>
                        <RecurringCalendarBreaks />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/profiles/:profile_slug"
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path="/:locale/profiles/:profile_slug"
                    element={<Profile />}
                  />
                  <Route
                    path={ROUTES.MESSAGES.ROOT}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.MESSAGES.ROOT}`}
                    element={
                      <RequireAuth>
                        <Messages />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.VIDEO_MEETING}
                    element={<LocaleRedirect />}
                  />
                  {/*  <Route
                    path={`/:locale${ROUTES.VIDEO_MEETING}`}
                    element={
                      <RequireAuth>
                        <MainVideoPage />
                      </RequireAuth>
                    }
                  /> */}
                  <Route path={ROUTES.FEEDBACK} element={<LocaleRedirect />} />
                  <Route
                    path={`/:locale${ROUTES.FEEDBACK}`}
                    element={
                      <RequireAuth>
                        <Feedback />
                      </RequireAuth>
                    }
                  />
                  {/* Admin */}
                  <Route
                    path={ROUTES.ADMIN.ROOT}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.ADMIN.ROOT}`}
                    element={
                      <RequireAuth>
                        <RequireAdminAuth>
                          <AdminPanel />
                        </RequireAdminAuth>
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.ADMIN.TRAINER_REVIEW}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.ADMIN.TRAINER_REVIEW}`}
                    element={
                      <RequireAuth>
                        <RequireAdminAuth>
                          <TrainerReviewAdmin />
                        </RequireAdminAuth>
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.ADMIN.FEEDBACK}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.ADMIN.FEEDBACK}`}
                    element={
                      <RequireAuth>
                        <RequireAdminAuth>
                          <FeedbackBoard />
                        </RequireAdminAuth>
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={ROUTES.ADMIN.USERS}
                    element={<LocaleRedirect />}
                  />
                  <Route
                    path={`/:locale${ROUTES.ADMIN.USERS}`}
                    element={
                      <RequireAuth>
                        <RequireAdminAuth>
                          <UsersBoard />
                        </RequireAdminAuth>
                      </RequireAuth>
                    }
                  />
                  {/* Catch all */}
                  <Route path="*" element={<Error404 />} />
                </Routes>
              </AuthProvider>
            </div>
            <ScrollToTopGlobalButton />
            <FeedbackNewTicket />
            <UpdateConfirmationPopUp />
            <ErrorConfirmationPopUp />
            <CookieNotification />
            {/*  <UploadProfileImage /> */}
            {/*   <UploadBannerImage /> */}
            {/*   <DeleteConfirmationPopUp /> */}
            {/* <ImageSliderFullScreen /> */}
            <SearchPopUp />
            <NewBugReportButton />
            <Footer />
          </Suspense>
        </ConstProvider>
      </CookiesProvider>
    </>
  );
};

export default App;
